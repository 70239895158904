import React, { useCallback, useEffect, useState } from "react";
import Notifier from "./notifier/index.js";
import PendencyMovementsModal from "./pendencyMovementsModal/index.js";
import { WarningAmberOutlined } from "@mui/icons-material";
import { connect } from "react-redux";
import pendencyService from "features/pendency/pendencyService.js";
import dayjs from "dayjs";
import history from "features/app/History";
import useCookNotification from "./hooks/useCookNotification.js";
import { EventoCliente } from "features/cliente/clienteConstants.js";
import { useMemo } from "react";
import { isLoggedUserInternal } from "features/shared/utils.js";
import { withRouter } from "react-router-dom";

const NOTIFICATIONS_CONFIG = {
  PENDENCIES: {
    ID: "pendencies",
    TITLE: "Movimentações pendentes",
    ICON: <WarningAmberOutlined color="error" />,
  },
  CLIENT_ALERT: {
    ID: "client_alert",
    TITLE: "Atenção!",
    ICON: <WarningAmberOutlined style={{ color: "#FFC944" }} />,
    DESCRIPTION: (name) => (
      <>
        Houve um problema ao tentar acessar o cliente <b>{name}</b>. Por favor,
        entre em contato com o suporte para mais detalhes.
      </>
    ),
    getIdNotification: (client) =>
      "cl" + client.IdCliente + "" + dayjs(client.StatusDataCriacao).valueOf(),
  },
};

const isValidPendencyData = (pendencyData) => dayjs(pendencyData).isValid();

const calcCounterBadgeNotifications = (
  itens,
  pendencyData,
  viewedNotifications
) => {
  return itens.reduce((prev, current) => {
    if (current.id === NOTIFICATIONS_CONFIG.PENDENCIES.ID) {
      if (!isValidPendencyData(pendencyData) || pendencyData === undefined) {
        return prev + 1;
      }
    }
    if (
      current.id === NOTIFICATIONS_CONFIG.CLIENT_ALERT.ID &&
      !viewedNotifications.includes(current.idNotification)
    )
      return prev + 1;
    return prev;
  }, 0);
};

const Notification = ({ farmId, clients, location }) => {
  const [itensNotification, setItensNotification] = useState([]);
  const [pendencyMovementsModal, setPendencyMovementsModal] = useState(false);
  const [pendencyData, setPendencyData] = useState();
  const [redirectToPendencies, setRedirectToPendencies] = useState(false);

  const [viewedNotifications, setViewedNotifications] = useState([]);

  const [
    getPendencie,
    setPendencie,
    setNotificationView,
    getNotificationsViewed,
  ] = useCookNotification();

  function onActionShowNotification(notificationId) {
    setViewedNotifications((prev) => {
      if (prev.includes(notificationId)) return prev;
      setNotificationView(notificationId);
      return [...prev, notificationId];
    });
  }

  useEffect(() => {
    const viewedNotifications = getNotificationsViewed();
    if (viewedNotifications) setViewedNotifications(viewedNotifications);
  }, []);

  useEffect(() => {
    setPendencyData(getPendencie(farmId));
  }, [farmId]);

  useEffect(() => {
    setPendencie(farmId, pendencyData);
  }, [pendencyData]);

  useEffect(() => {
    if (
      redirectToPendencies &&
      isValidPendencyData(pendencyData) &&
      pendencyData !== undefined
    ) {
      history.push("/movimentacoesPendentes");
    }
  }, [redirectToPendencies, pendencyData]);

  const actionPendencyNotification = useCallback(
    (callback) => {
      if (!isValidPendencyData(pendencyData) || pendencyData === undefined) {
        callback();
        setRedirectToPendencies(true);
        return;
      }

      history.push("/movimentacoesPendentes");

      return;
    },
    [pendencyData]
  );

  const checkPendencies = useCallback(
    async (farmId) => {
      try {
        const result = await pendencyService.getLastPendencyByFarmId(farmId);

        const baseDate = dayjs().subtract(7, "days");

        if (
          result &&
          result.data &&
          result.data.data &&
          baseDate.isBefore(result.data.data.operationDate)
        ) {
          const pendenciesDateStorage = pendencyData;
          if (
            pendenciesDateStorage &&
            dayjs(Number(pendenciesDateStorage)).isBefore(
              result.data.data.operationDate
            )
          ) {
            setPendencyData(null);
          }

          const setDatePendenciesInLocalStorage = () => {
            setPendencyData(dayjs(result.data.data.operationDate).valueOf());
          };

          return {
            icon: NOTIFICATIONS_CONFIG.PENDENCIES.ICON,
            title: NOTIFICATIONS_CONFIG.PENDENCIES.TITLE,
            id: NOTIFICATIONS_CONFIG.PENDENCIES.ID,
            action: () => {
              actionPendencyNotification(setDatePendenciesInLocalStorage);
            },
          };
        }
      } catch (error) {
        return null;
      }

      return null;
    },
    [pendencyData, actionPendencyNotification]
  );

  const checkClients = useCallback(() => {
    if (!isLoggedUserInternal()) return [];

    const baseDate = dayjs().subtract(7, "days");
    const suspendClients = clients.filter(
      (client) =>
        client.StatusEvento === EventoCliente.SUSPENSAO &&
        dayjs(client.StatusDataCriacao).isAfter(baseDate)
    );

    return suspendClients.map((client) => ({
      icon: NOTIFICATIONS_CONFIG.CLIENT_ALERT.ICON,
      title: NOTIFICATIONS_CONFIG.CLIENT_ALERT.TITLE,
      id: NOTIFICATIONS_CONFIG.CLIENT_ALERT.ID,
      idNotification:
        NOTIFICATIONS_CONFIG.CLIENT_ALERT.getIdNotification(client),
      description: NOTIFICATIONS_CONFIG.CLIENT_ALERT.DESCRIPTION(
        client.NomeReduzido
      ),
      actionOnShow: () =>
        onActionShowNotification(
          NOTIFICATIONS_CONFIG.CLIENT_ALERT.getIdNotification(client)
        ),
    }));
  }, [clients, onActionShowNotification]);

  const checkItensToNotificate = async (farmId) => {
    const itens = [];
    const notificationsClients = checkClients();
    const notificatePendencies = await checkPendencies(farmId);
    if (notificatePendencies) itens.push(notificatePendencies);
    if (notificationsClients) itens.push(...notificationsClients);

    setItensNotification(itens);
  };

  useEffect(() => {
    if (farmId) {
      checkItensToNotificate(farmId);
    }
  }, [farmId, pendencyData, clients, location.pathname]);

  const countBadge = useMemo(
    () =>
      calcCounterBadgeNotifications(
        itensNotification,
        pendencyData,
        viewedNotifications
      ),
    [itensNotification, pendencyData, viewedNotifications]);

  return (
    <>
      <Notifier itens={itensNotification} badgeCount={countBadge} />
      {pendencyMovementsModal ? (
        <PendencyMovementsModal
          onClose={() => setPendencyMovementsModal(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ fazenda, cliente }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
    currentFarm: fazenda.currentFarm,
    clients: cliente.Clientes
  };
};

export default withRouter(connect(mapStateToProps, null)(Notification));
